export default [
  {
    q: "What am I getting myself into?",
    a: `<p>Listen up, anon, this is a matter of life-and-death! Once you step foot into this website and start messin' around with the smart contract, you're basically giving the universe full consent: \"Hey, I'm ready to take on some wild and crazy stuff!\" We're talkin' about experimental, totally out-there smart contracts, and that means there might be a few bumps in the road. But hey, you're a thrill-seeker, right? So buckle up, 'cause you buying the ticket is signing up for the ride of your life! Here, why not hear it from my friend, Hunter S. Thompson:</p>
        <p class="italic">“No sympathy for the devil; keep that in mind. Buy the ticket, take the ride...and if it occasionally gets a little heavier than what you had in mind, well...maybe chalk it up to forced consciousness expansion: Tune in, freak out, get beaten.”</p>`,
  },
  {
    q: "So, how do I get in the game, bro?",
    a: "<p>You must have Ethereum in your Metamask wallet to be able to participate. You can mint by selecting the amount of WacDonald's Cryptopoly asset tiles (WacDonald's terminology for the NFTs) you want to acquire and then press the big button that says mint. Thereafter, Metamask will display a pop-up. Selecting your gas speed, we recommend high! Then, submit your transaction. Once your transaction is confirmed on the blockchain. The unrevealed asset tiles will be in your wallet. You’ll be able to view and trade them on OpenSea within a few minutes after the transaction is confirmed. The NFTs will be revealed approximately 24-72 hours after the sale concludes. </p>",
  },
  {
    q: "I minted asset tiles but I don’t know to which Cryptopoly it belongs to, did I get scammed?",
    a:
      "<h5>Has it been less than 24 hours from selling completely out?</h5>" +
      "<p>No, you did not get scammed, we are in the business of serving up delicious artistic parody. The only scam is that I’m still working at WacDonald's. The asset tiles will be revealed approximately 24-72 hours after all of the asset tiles have been sold out. Additionally, we’ll make sure to announce the reveal on Twitter & Discord so check there! </p>" +
      "\n<h5>Has it been more than 24 hours from selling completely out?</h5>" +
      "<p>No, you did not get scammed, we are in the business of serving up delicious artistic parody. The only scam is that I’m still working at WacDonald's. The asset tiles are likely still updating their metadata on OpenSea. This may be displayed as some asset tiles being revealed on OpenSea and others have not. Do not be afraid! Open your unrevealed asset tile on OpenSea. There’s a button that will request up-to-date metadata that will lead to reveal some time after.  In other words, be patient!</p>",
  },
  {
    q: "Why is no one buying mine for what I listed it for? ",
    a: "<p class='mb-0'>We have no control over secondary markets.  We have absolutely no way of predicting how reception will be to this endeavor. What we created was an interactive art experience that we hope will be something enjoyable and more than just financial incentive. This is something that the community manifests and is for the most part out of control of the team’s hands. That said, we hope to foster a fair, enjoyable experience that hopefully can be replicated again if the community desires.</p>",
  },
  {
    q: "What! Someone got a monopoly with only 2 mints! Why did I get such trash? ",
    a: "<p>We actually have no control over which asset tiles you mint. We swear but, don’t take our word for it, instead, take RNJesus’s provably-fair and verifiable word for it.</p>",
  },
  {
    q: "Fine, what if I only mint one? This seems rigged for high rollers! I thought WacDonald’s was known for its cheapness?",
    a: "<p>Sir, we discontinued the 1 ETH menu awhile ago due to inflation. Additionally, my salary hasn’t adjusted accordingly either. Not that you care. That said, this is the time to stick it to those whales! They need your asset tiles in order to make a Cryptopoly. Take it to OpenSea and list that asset tile competitively and make them pay for being so lardy. It’s your turn to price gouge them, shrimp!</p>",
  },
  {
    q: "Does WacDonald’s store any of my information?",
    a: "<p>Bruh, look, I am just a cashier at WacDonalds. I don’t want to keep myself here. I am not going to subject anything else to staying in this toxic trash. WacDonald’s has health codes to upkeep, they won’t allow for anything to smudge their reputation, aight?</p>",
  },
  {
    q: "You are just a cashier, I want to speak to the manager of this WacDonald's right now!",
    a: "<p>You can find the owner of this fine establishment likely on Discord in our #help channel. You may also reach my superior at <a href='mailto:support@WacDonalds.io'>support@WacDonalds.io</a>. We hope you enjoyed minting with us and have a nice day!</p>",
  },
];
