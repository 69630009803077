import React from "react";
import "../styles/about.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InView } from "react-intersection-observer";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faFileAlt } from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <section className="about">
      <Container>
        <Row>
          <Col xs={12} md={7} lg={8} xl={9}>
            <InView triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div
                  ref={ref}
                  className={`animate section__title ${
                    inView ? "animate--right" : ""
                  }`}
                >
                  <h1 className="section__title">ABOUT CRYPTOPOLY</h1>
                </div>
              )}
            </InView>
            <InView triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div
                  ref={ref}
                  className={`animate ${inView ? "animate--up" : ""}`}
                  style={{ animationDelay: "0.25s" }}
                >
                  <p className="about__text">
                    Welcome to WacDonald's, the place where you can score the
                    craziest Cryptopoly game in the entire metaverse, anon! Each
                    item on our menu comes with an ERC-721 token, which
                    represents a super cool asset tile. And guess what, anon? If
                    you happen to get a Cryptopoly, you can come back and cash
                    it in for an NFT prize with a side of ETH right away!
                  </p>
                  <p className="about__text">
                    By engaging with this website and its associated smart
                    contract, you are acknowledging and embracing the potential
                    risks inherent in experimental and innovative smart contract
                    technology.
                  </p>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        <div className="mb-0 ps-0 d-flex w-100 align-items-center">
                          <FontAwesomeIcon
                            className="accordion__icon me-2 mt-0"
                            icon={faChevronDown}
                          />
                          Find Out More
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="faq-answer ps-0">
                        <div className="ps-0">
                          <p className="about__text">
                            WacDonald's Cryptopoly is like, totally on-chain and
                            provably fair, anon! It's a wild NFT trading art
                            experience, and each group of assets represents
                            something really important in crypto culture. We're
                            all about celebrating the sheer ridiculousness of
                            crypto, and we're doing it in a way that's just as
                            ridiculous and fun!
                          </p>
                          <p className="about__text">
                            We got a total of 8888 tiles available for minting,
                            anon. It's a big project, and we're giving back from
                            day one! We're developing WacDonald's Cryptopoly
                            into a game that's all about the players, anon, and
                            ETH from minting goes straight back to the
                            community!
                          </p>

                          <p className="about__text">
                            Now, listen up, anon. We made sure this thing is
                            random and fair to everyone, from degens, apes, and
                            collectors.. Everybody's got a shot at those sweet
                            rewards!
                          </p>
                          <p className="about__text">
                            And, hey, anon, if you're one of the collectors in
                            round one, you get might get some special perks in
                            the future! No promises, anon, you never know if the
                            unique NFT rewards could get you more than you
                            bargained for!!
                          </p>
                          <p className="about__text">
                            So, anon, welcome to WacDonald's, the only place in
                            the metaverse to get your hands on Cryptopoly! How
                            can we help you today, buddy?
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            </InView>
          </Col>
          <Col xs={12} md={5} lg={4} xl={3}>
            <InView triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div
                  ref={ref}
                  className={`animate ${
                    inView ? "animate--left" : ""
                  } text-center`}
                  style={{ animationDelay: "0.55s" }}
                >
                  <img
                    className="w-75 w-md-100 mt-4 mt-md-0"
                    src="/assets/imgs/mr-cryptopoly-color.png"
                    alt="About Cryptopoly"
                  />
                </div>
              )}
            </InView>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
