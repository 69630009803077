import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain";
import { web3Call, contractSuccessSet } from "../redux/contract";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link, scroller } from "react-scroll";
import { isMobile } from "react-device-detect";

import "../styles/header.scss";

function HeaderComingSoon() {
  const dispatch = useDispatch();

  // THIS WILL UPDATE AT MOMENT OF PURCHASE

  return (
    <Navbar className="navbar-main" bg="light" fixed="top">
      <Container>
        <Navbar.Brand
          onClick={() =>
            scroller.scrollTo("landing", {
              smooth: true,
              offset: 0,
              duration: 300,
            })
          }
        >
          <img
            height="40"
            src="/assets/imgs/Mr.Cryptopoly_face.png"
            alt="Wacdonald's Cryptopoly Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Link
            className="nav-link me-0"
            to="faq"
            smooth={true}
            offset={-65}
            duration={300}
          >
            FAQ
          </Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderComingSoon;
