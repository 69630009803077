import React, { useEffect, useState } from "react";
import "../styles/typewriter.scss";

function Typewriter(props) {
  const [text, setText] = useState("");

  let i = 0;
  let txt =
    "Welcome to WacDonald's Cryptopoly: mint, trade, redeem & win! Hungry? Order now!"; /* The text */
  var speed = 50; /* The speed/duration of the effect in milliseconds */
  var waitTime = 4000;

  useEffect(() => {
    function typeWriter() {
      if (i < txt.length) {
        if (txt.charAt(i) === "!") {
          setText((text) => text + txt.charAt(i));
          setTimeout(() => {
            i++;
            setTimeout(typeWriter, speed);
          }, 1000);
        } else {
          setText((text) => text + txt.charAt(i));
          i++;
          setTimeout(typeWriter, speed);
        }
      } else {
        setTimeout(() => {
          setText("");
          i = 0;
          setTimeout(() => {
            typeWriter();
          }, 1000);
        }, waitTime);
      }
    }
    if (props.inView) {
      setTimeout(() => {
        typeWriter();
      }, 1000);
    }
  }, [props.inView]);

  return <div id="typewriter">{text}</div>;
}

export default Typewriter;
