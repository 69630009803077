import React from "react";
import ReactHtmlParser from "react-html-parser";
import Container from "react-bootstrap/Container";
import { Accordion } from "react-bootstrap";
import "../styles/faq.scss";
import faqs from "./../faq";
import { InView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function Faq() {
  return (
    <section id="faq" className="faq">
      <Container>
        <InView triggerOnce={true}>
          {({ inView, ref, entry }) => (
            <div
              ref={ref}
              className={`animate ${inView ? "animate--down" : ""}`}
              style={{ animationDelay: "0.75s" }}
            >
              <img
                className="faq__img"
                src="assets/svgs/coin-heads.svg"
                alt="Big Wac"
              />
              <img
                className="faq__img --faq2"
                src="assets/svgs/coin-heads.svg"
                alt="Big Wac"
              />
            </div>
          )}
        </InView>

        <InView triggerOnce={true}>
          {({ inView, ref, entry }) => (
            <div
              ref={ref}
              className={`animate ${inView ? "animate--left" : ""}`}
            >
              <h1 className="section__title text-center">FAQs</h1>
            </div>
          )}
        </InView>

        <Accordion defaultActiveKey="0">
          {faqs.map((faq, idx) => (
            <InView triggerOnce={true} key={idx}>
              {({ inView, ref, entry }) => (
                <div
                  ref={ref}
                  className={`animate ${inView ? "animate--right" : ""}`}
                >
                  <Accordion.Item eventKey={idx}>
                    <Accordion.Header>
                      <h4 className="mb-0 d-flex w-100">
                        <FontAwesomeIcon
                          className="accordion__icon me-3"
                          icon={faChevronDown}
                        />
                        {faq.q}
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body className="faq-answer">
                      {ReactHtmlParser(faq.a)}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              )}
            </InView>
          ))}
        </Accordion>
      </Container>
    </section>
  );
}

export default Faq;
