import React from "react";

import "./App.scss";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import HeaderComingSoon from "./components/HeaderComingSoon";

import About from "./components/About";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import Ticker from "./components/Ticker";
import LandingComingSoon from "./components/LandingComingSoon";
import { ParallaxProvider } from "react-scroll-parallax";

export const StyledButton = styled.button`
  padding: 8px;
`;

function App() {
  return (
    <ParallaxProvider>
      <s.Screen>
        <HeaderComingSoon />
        <LandingComingSoon />
        <About />
        <Ticker />
        <Faq />
        <Footer />
      </s.Screen>
    </ParallaxProvider>
  );
}

export default App;
